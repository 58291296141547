
import { ref, onMounted, computed, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import { DatePicker } from "v-calendar";
import moment from "moment";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import AddVehicle from "./AddVehicle.vue";
import SuccessApointment from "./SuccessApointment.vue";
import { priceFormat, kmFormat } from "../../../helpers/globalFuntions";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
interface contactData {
  name: string;
  last_name: string;
  email: string;
  phone: string;
}

export default {
  name: "ScheduleAppointment",
  components: {
    DatePicker,
    ErrorMessage,
    Field,
    Form,
    AddVehicle,
    SuccessApointment,
  },
  setup() {
    const dates = ref<any>([]);
    const Alldates = ref<any>([]);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const route = useRoute();
    const dateCalendar = ref<any>(null);
    const vehicle = ref<any>({});
    const calendar = ref();
    const hoursDate = ref("");

    const regexPhone = /^[1-9]\d{2}-\d{3}-\d{4}?$/;
    const validationSchema = Yup.object().shape({
      name: Yup.string().required("Nombre es obligatorio").label("Nombre"),
      last_name: Yup.string()
        .required("Los apellidos son obligatorios")
        .label("Apellidos"),
      email: Yup.string()
        .required("El correo es obligatorio")
        .email("Ingresa un email valido")
        .label("Correo"),
      phone: Yup.string()
        .matches(regexPhone, "El teléfono es incorrecto")
        .required("El Teléfono es obligatorio")
        .label("Teléfono"),
    });

    const firstCharToUppercase = (text) => {
      return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const vehicleClient = ref();

    const assignVehicle = (val: any) => {
      vehicleClient.value = val;
    };

    const portada = computed(() => {
      if (vehicle.value.images.length > 0) {
        let cover = vehicle.value.images.filter((i) => i.cover == 1);
        if (cover.length > 0) {
          console.log(cover);
          return cover[0];
        } else {
          return vehicle.value.images[0];
        }
      } else {
        return { path: "" };
      }
    });
    const getDetail = async (id) => {
      try {
        const { data } = await ApiService.get("/api/vehicle/" + id);
        vehicle.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const getCalendar = async () => {
      const idAgency = vehicle.value.agency.id;
      try {
        
        console.log("Entra 2");
        const date = new Date();
        let mes = date.getMonth() + 1;
        console.log(date.getFullYear() + '-' + mes + '-' + date.getDate());


        let start_month = date.getMonth() + 1;
        let start_year = date.getFullYear();
        let end_month = start_month + 6;
        let end_year = end_month > 12 ? start_year + 1 : start_year;
        end_month > 12 ? (end_month = 1) : false;

        let start_date = `${start_year}-${start_month}-1`;
        let end_date = `${end_year}-${end_month}-1`;
        let configDates = {
          fecha_inicial: start_date,
          fecha_final: end_date,
        };
        console.log(configDates);
        const { data } = await axios.post(
          `/api/citas/calendar/2/${idAgency}`,
          configDates
        );
        dates.value = Object.keys(data.data);
        dates.value = dates.value.map((d) => {
          let from = d.split("-");
          if(Date.parse(d) > Date.parse(date.getFullYear() + '-' + mes + '-' + date.getDate())){
            return {
              start: new Date(parseInt(from[0]), parseInt(from[1]) - 1, parseInt(from[2])),
              end: new Date(parseInt(from[0]), parseInt(from[1]) - 1, parseInt(from[2])),
            };
          }
        });

        Alldates.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const times = computed(() => {
      if (dateCalendar.value) {
        return Alldates.value[
          moment(dateCalendar.value.toString()).format("YYYY-MM-DD").toString()
        ];
      } else {
        return [];
      }
    });

    let selectedDate = ref("");
    watch(dateCalendar, (current, prev) => {
      idDate.value = 0;
      moment.locale("es");
      if (current != prev) {
        hoursDate.value = "";
        selectedDate.value =
          moment(dateCalendar.value.toString()).format("dddd") +
          " " +
          moment(dateCalendar.value.toString()).format("LL");

        console.log("returnwatch", selectedDate);
      } else {
        return "Selecciona una fecha y un horario";
      }
    });

    const appointment = ref<any>(null);
    const submit = async (values, { resetForm, setFieldError }) => {
      if (!submitButtonRef.value) {
        return;
      }
      if (idDate.value == 0) {
        Swal.fire({
          text: "Debes seleccionar una fecha y hora",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let dataSend = {};
      if (vehicleClient.value) {
        dataSend = {
          tipo: 2,
          fecha: idDate.value,
          nombre: values.name,
          apellidos: values.last_name,
          email: values.email,
          phone: values.phone,
          vehicle: route.params.id,
          a_cuenta: [
            {
              marca: vehicleClient.value.brand.name,
              modelo: vehicleClient.value.model.name,
              year: vehicleClient.value.year.name,
              version: vehicleClient.value.version.name,
              kms: vehicleClient.value.km,
              propietarios: 1,
            },
          ],
        };
      } else {
        dataSend = {
          tipo: 2,
          fecha: idDate.value,
          nombre: values.name,
          apellidos: values.last_name,
          email: values.email,
          phone: values.phone,
          vehicle: route.params.id,
        };
      }
      try {
        const { data } = await ApiService.post("/api/site/citas", dataSend);
        resetForm();
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;

          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }
        appointment.value = {
          ...values,
          vehicle: { ...vehicle.value, id: route.params.id },
          vehicleClient: vehicleClient.value,
          selectedDate,
          hoursDate,
        };
        return;
      } catch (error: any) {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;

          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }
        Swal.fire({
          text: error.response.data.message
            ? error.response.data.message
            : "Error al enviar la información",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }

      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;

        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
    };
    const idDate = ref(0);
    const saveIdDate = (val: any) => {
      idDate.value = val.id;
    };

    onMounted(async () => {
      await getDetail(route.params.id);
      await getCalendar();
    });
    const contactData = ref<contactData>({
      name: "",
      last_name: "",
      email: "",
      phone: "",
    });

    return {
      saveIdDate,
      vehicle,
      dateCalendar,
      calendar,
      hoursDate,
      selectedDate,
      validationSchema,
      contactData,
      priceFormat,
      kmFormat,
      portada,
      vehicleClient,
      assignVehicle,
      submitButtonRef,
      appointment,
      submit,
      dates,
      times,
      firstCharToUppercase,
    };
  },
};
