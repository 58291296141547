
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import loaderClientVue from "../components/loader-client.vue";

interface contactData {
  year: string;
  brand: string;
  model: string;
  version: string;
  km: string;
}
interface brand {
  id: string;
  name: string;
}
interface model {
  id: string;
  name: string;
}
interface year {
  id: string;
  name: string;
}
interface version {
  id: string;
  name: string;
}

export default defineComponent({
  name: "AddVehiculo",
  components: {
    ErrorMessage,
    loaderClientVue,
    Field,
    Form,
  },
  props: {
    idVehicle: Number,
  },
  setup(props, { emit }) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newCarModalRef = ref<null | HTMLElement>(null);
    const formContact = ref<any>(null);
    const resetBtn = ref<any>(null);
    const brands = ref<brand[]>([]);
    const models = ref<model[]>([]);
    const years = ref<year[]>([]);
    const versions = ref<version[]>([]);
    const store = useStore();
    const showLoader = ref(false);

    const contactData = ref<contactData>({
      year: "1",
      brand: "1",
      model: "1",
      version: "1",
      km: "",
    });

    const validationSchema = Yup.object().shape({
      year: Yup.string()
        .notOneOf(["1"], "Año es obligatorio")
        .required("Año es obligatorio")
        .label("Año"),
      brand: Yup.string()
        .notOneOf(["1"], "La marca es obligatoria")
        .required("La marca es obligatoria")
        .label("Marca"),
      model: Yup.string()
        .notOneOf(["1"], "El modelo es obligatorio")
        .required("El modelo es obligatorio")
        .label("Modelo"),
      version: Yup.string()
        .notOneOf(["1"], "La versión es obligatoria")
        .required("La versión es obligatoria")
        .label("Version"),
      km: Yup.string().required("El kilometraje es obligatorio").label("Km"),
    });

    const formatNumber = (value) => {
      let val = (value / 1).toFixed(0);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const addKm = () => {
      let kilometraje = formatNumber(contactData.value.km);
      if (!isNaN(parseInt(kilometraje))) {
        contactData.value.km = kilometraje;
      } else {
        contactData.value.km = "";
      }
    };

    const submit = async (values: any) => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      const dataToReturn = {
        year: years.value.find((y) => y.id === values.year),
        model: models.value.find((y) => y.id === values.model),
        brand: brands.value.find((y) => y.id === values.brand),
        version: versions.value.find((y) => y.id === values.version),
        km: values.km.replace(/,/g, ""),
      };
      emit("vehicle", dataToReturn);
      hideModal(newCarModalRef.value);
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;

        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
    };

    const getBrands = async () => {
      showLoader.value = true;
      let url = "/api/maxi/brands/v_1";
      try {
        const { data } = await ApiService.get(url);
        brands.value = data.data;
      } catch (error) {
        console.log(error);
      }
      showLoader.value = false;
    };
    const getModels = async () => {
      showLoader.value = true;
      models.value = [];
      years.value = [];
      versions.value = [];
      contactData.value.model = "1";
      contactData.value.year = "1";
      contactData.value.version = "1";
      let url = "/api/maxi/models/" + contactData.value.brand;
      try {
        const { data } = await ApiService.get(url);
        models.value = data.data;
      } catch (error) {
        console.log(error);
      }
      showLoader.value = false;
    };
    const getYears = async () => {
      showLoader.value = true;
      years.value = [];
      versions.value = [];
      contactData.value.year = "1";
      contactData.value.version = "1";
      let url = `/api/maxi/years/${contactData.value.model}`;
      try {
        const { data } = await ApiService.get(url);
        years.value = data.data;
      } catch (error) {
        console.log(error);
      }
      showLoader.value = false;
    };
    const getVersions = async () => {
      showLoader.value = true;
      versions.value = [];
      contactData.value.version = "1";
      let url = `/api/maxi/versions/${contactData.value.year}`;
      try {
        const { data } = await ApiService.get(url);
        versions.value = data.data;
      } catch (error) {
        console.log(error);
      }
      showLoader.value = false;
    };

    onMounted(async () => {
      await getBrands();
    });

    return {
      contactData,
      showLoader,
      validationSchema,
      submit,
      submitButtonRef,
      newCarModalRef,
      formContact,
      resetBtn,
      brands,
      getModels,
      models,
      getYears,
      years,
      getVersions,
      versions,
      addKm,
    };
  },
});
