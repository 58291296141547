<template>
  <div class="container-xxl" v-if="vehicle">
    <div class="card my-10 col-sm-12 col-md-10 col-lg-8 mx-auto px-5 bg-gray-site">
      <div class="text-center">
        <img
          width="80"
          class="my-7"
          src="media/icons/iconsax/Apartado_exitoso.svg"
          alt="apartado"
        />
        <h1 class="fs-1 fw-bold my-4">Tu cita fue agendada exitosamente</h1>
        <p class="fs-5 my-2">
          Tu cita para {{ vehicle.brand }} {{ vehicle.model }} {{ vehicle.version }} será
          <span class="text-blue"> {{ appointment.selectedDate }} {{ appointment.hoursDate }}</span>
        </p>
      </div>
      <div class="col-md-10 mx-auto my-5">
        <div class="row gx-6">
          <div class="col-xs-12 col-md-6 mx-auto h-100 mb-8 mb-md-0">
            <router-link
              :to="{ name: 'car-detail', params: { id: vehicle.id } }"
              class="card card-body rounded-3 p-0 border border-gray-600 bg-gray-site"
            >
              <div class="container-img position-relative">
                <img
                  v-if="images.length > 0"
                  class="img-card rounded-top"
                  :src="images[0].path"
                  alt=""
                />
                <!-- <GalleryCard v-if="vehicle.images.length > 0" :images="vehicle.images" /> -->
              </div>
              <div class="col-12 px-5 py-3">
                <div>
                  <p class="mb-1">
                    {{ vehicle.brand + " " + vehicle.model + " " + vehicle.version }}
                  </p>
                  <p class="mb-4 text-gray fs-sm">
                    {{ vehicle.year }} - {{ kmFormat(vehicle.Mileage) + " km" }} -
                    {{ vehicle.Estado }}
                  </p>
                </div>
                <p class="text-success fw-bold">${{ priceFormat(vehicle.Price) }}</p>
              </div>
            </router-link>
          </div>
          <div class="col-xs-12 col-md-6 mx-auto h-100">
            <div ref="mapDiv" class="rounded" style="width: 100%; height: 200px"></div>
            <div class="d-flex align-items-center my-5">
              <div
                class="d-flex justify-content-center align-items-center w-40px h-40px w-md-50px h-md-50px rounded-3 bg-gray-lighten"
              >
                <i class="text-white isax-shop fs-2"></i>
              </div>
              <div class="ms-3 h-40px h-md-50px">
                <p class="mb-1">{{ vehicle.agency.name }}</p>
                <h6 class="text-gray fs-sm">{{ vehicle.agency.address }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col">
            <a
              target="_blank"
              class="btn btn-success my-3 fs-base w-75 w-md-50 me-3"
              :href="vehicle.agency.url_whatsapp"
            >
              <inline-svg class="w-16px h-16px" src="media/icons/iconsax/Whatsapp.svg" />
              Contactar por WhatsApp
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { Loader } from "@googlemaps/js-api-loader";
const API_KEY = "AIzaSyACDXm5WBEngJZHLoEiSzzYCdlkg3THQmI";
import { ref, onMounted, watch } from "vue";
import { kmFormat, priceFormat } from "../../../helpers/globalFuntions";
import GalleryCard from "../components/GalleryCard.vue";

export default {
  name: "SuccessApointment",
  components: {
    GalleryCard,
  },
  props: {
    appointment: Object,
  },
  setup(props) {
    const mapDiv = ref(null);
    const loader = new Loader({ apiKey: API_KEY });
    const map = ref();
    const images = ref([]);
    const vehicle = ref();

    const formatPrice = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    watch(
      () => props.appointment,
      async () => {
        await loader.load();

        vehicle.value = {
          id: props.appointment.vehicle.id,
          model: props.appointment.vehicle.model.model,
          brand: props.appointment.vehicle.brand.brand,
          version: props.appointment.vehicle.version.version,
          year: props.appointment.vehicle.year.year,
          Mileage: props.appointment.vehicle.mileage,
          Price: props.appointment.vehicle.price,
          images: props.appointment.vehicle.images,
          Estado: props.appointment.vehicle.agency.state,
          agency: props.appointment.vehicle.agency,
        };
        images.value = vehicle.value.images.filter((i) => i.type == "Vehiculo");
        if (vehicle.value.agency.latitude && vehicle.value.agency.longitude) {
          map.value = new google.maps.Map(mapDiv.value, {
            center: {
              lat: parseFloat(vehicle.value.agency.latitude),
              lng: parseFloat(vehicle.value.agency.longitude),
            },
            zoom: 13,
          });
          new google.maps.Marker({
            position: {
              lat: parseFloat(vehicle.value.agency.latitude),
              lng: parseFloat(vehicle.value.agency.longitude),
            },
            map: map.value,
            title: vehicle.value.agency.name,
          });
        }
      }
    );

    onMounted(async () => {
      console.log("vehicle", props.appointment.vehicle);
      vehicle.value = {
        id: props.appointment.vehicle.id,
        model: props.appointment.vehicle.model.model,
        brand: props.appointment.vehicle.brand.brand,
        version: props.appointment.vehicle.version.version,
        year: props.appointment.vehicle.year.year,
        Mileage: props.appointment.vehicle.mileage,
        Price: props.appointment.vehicle.price,
        images: props.appointment.vehicle.images,
        Estado: props.appointment.vehicle.agency.state,
        agency: props.appointment.vehicle.agency,
      };
      images.value = vehicle.value.images.filter((i) => i.type == "Vehiculo");
      await loader.load();
      if (vehicle.value.agency.latitude && vehicle.value.agency.longitude) {
        map.value = new google.maps.Map(mapDiv.value, {
          center: {
            lat: parseFloat(vehicle.value.agency.latitude),
            lng: parseFloat(vehicle.value.agency.longitude),
          },
          zoom: 13,
        });
        new google.maps.Marker({
          position: {
            lat: parseFloat(vehicle.value.agency.latitude),
            lng: parseFloat(vehicle.value.agency.longitude),
          },
          map: map.value,
          title: vehicle.value.agency.name,
        });
      }
    });

    return {
      vehicle,
      mapDiv,
      images,
      kmFormat,
      priceFormat,
      formatPrice,
    };
  },
};
</script>

<style lang="scss" scoped>
.img-card {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.container-img {
  position: relative;
  height: 155px;
  @media screen and (max-width: 500px) {
    height: auto;
  }
}
a,
a:hover {
  color: white;
}
</style>
